import React, { useState, useEffect, useContext } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
import { toast } from "react-toastify";

import CopyToClipboard from "../../components/CopyToClipboard";

import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},

}));

const Settings = () => {
	const { user: loggedInUser } = useContext(AuthContext);
	const classes = useStyles();

	const [settings, setSettings] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);	

	useEffect(() => {
		const socket = openSocket();

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async (e, key) => {
		const selectedValue = e.target.value;
	
		try {
		  await api.put(`/settings/${key}`, {
			value: selectedValue,
		  });
		  toast.success(i18n.t("settings.success"));
		} catch (err) {
		  toastError(err);
		}
	  };
	
	  const getSettingValue = key => {
		const setting = settings.find(s => s.key === key);
		return setting ? setting.value : '';
	  };

	  const getSettingKey = key => {
		const setting = settings.find(s => s.key === key);
		return setting ? setting.key : '';
	  };

	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth="sm">
				<Typography variant="body2" gutterBottom>
					{i18n.t("settings.title")}
				</Typography>
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.userCreation.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userCreation-setting"
						name="userCreation"
						value={
							settings && settings.length > 0 && getSettingValue("userCreation")
						}
						className={classes.settingOption}
						onChange={(e) => handleChangeSetting(e, getSettingKey("userCreation"))}
						>
						<option value="enabled">
							{i18n.t("settings.settings.userCreation.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.userCreation.options.disabled")}
						</option>
						</Select>
				</Paper>

				{/* NOVO SELECT PARA HABILITAR E DESABILITAR ENVIO DE MENSAGEM AO ACEITAR TICKET */}
				
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.sendGreetingAccepted.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="sendGreetingAccepted-setting"
						name="sendGreetingAccepted"
						value={
							settings && settings.length > 0 && getSettingValue("sendGreetingAccepted")
						}
						className={classes.settingOption}
						onChange={(e) => handleChangeSetting(e, getSettingKey("sendGreetingAccepted"))}
						>
						<option value="enabled">
							{i18n.t("settings.settings.sendGreetingAccepted.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.sendGreetingAccepted.options.disabled")}
						</option>
						</Select>
				</Paper>

				{/* NOVO SELECT PARA HABILITAR E DESABILITAR PESQUISA DE SATISFACAO */}
				
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.userRating.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userRating-setting"
						name="userRating"
						value={
							settings && settings.length > 0 && getSettingValue("userRating")
						}
						className={classes.settingOption}
						onChange={(e) => handleChangeSetting(e, getSettingKey("userRating"))}
						>
						<option value="enabled">
							{i18n.t("settings.settings.userRating.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.userRating.options.disabled")}
						</option>
						</Select>
				</Paper>

				{/* NOVO SELECT PARA HABILITAR E DESABILITAR ENVIO DE MENSAGEM AO TRANSFERIR TICKET */}
				
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.sendMsgTransfTicket.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="sendMsgTransfTicket-setting"
						name="sendMsgTransfTicket"
						value={
							settings && settings.length > 0 && getSettingValue("sendMsgTransfTicket")
						}
						className={classes.settingOption}
						onChange={(e) => handleChangeSetting(e, getSettingKey("sendMsgTransfTicket"))}
						>
						<option value="enabled">
							{i18n.t("settings.settings.sendMsgTransfTicket.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.sendMsgTransfTicket.options.disabled")}
						</option>
						</Select>
				</Paper>


				{/* NOVO SELECT PARA HABILITAR E DESABILITAR GRUPOS */}
				
				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.groupEnable.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="groupEnable-setting"
						name="groupEnable"
						value={
							settings && settings.length > 0 && getSettingValue("groupEnable")
						}
						className={classes.settingOption}
						onChange={(e) => handleChangeSetting(e, getSettingKey("groupEnable"))}
						>
						<option value="enabled">
							{i18n.t("settings.settings.groupEnable.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.groupEnable.options.disabled")}
						</option>
						</Select>
				</Paper>

				<Divider style={{ margin: '20px 0' }} />
			
				{/* Campos para Quantidade de Conexões e Quantidade de Usuários */}
				<Can
					role={loggedInUser.profile}
					perform="setting-control:editqtdconexoes"
					yes={() => (
						<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.limitacoes.quantidadeconexao")}
						</Typography>
						<TextField
							margin="dense"
							variant="outlined"
							id="connectionLimit-setting"
							name="connectionLimit"
							value={getSettingValue("Quantidade de Conexoes")}
							className={classes.settingOption}
							onChange={e => handleChangeSetting(e, "Quantidade de Conexoes")}
						/>
						</Paper>
					)}
				/>
				<Can
					role={loggedInUser.profile}
					perform="setting-control:editqtdusuarios"
					yes={() => (
						<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.limitacoes.quantidadeusuario")}
						</Typography>
						<TextField
							margin="dense"
							variant="outlined"
							id="userLimit-setting"
							name="userLimit"
							value={getSettingValue("Quantidade de Usuarios")}
							className={classes.settingOption}
							onChange={e => handleChangeSetting(e, "Quantidade de Usuarios")}
						/>
						</Paper>
					)}
				/>

				<Can
					role={loggedInUser.profile}
					perform="setting-control:tokenAssas"
					yes={() => (
						<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.limitacoes.tokenAssas")}
						</Typography>
						<TextField
							margin="dense"
							variant="outlined"
							id="tokenAssas"
							name="tokenAssas"
							value={getSettingValue("tokenAssas")}
							className={classes.settingOption}
							onChange={e => handleChangeSetting(e, "tokenAssas")}
						/>
						</Paper>
					)}
				/>

				<Can
					role={loggedInUser.profile}
					perform="setting-control:ClienteID"
					yes={() => (
						<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.limitacoes.ClienteID")}
						</Typography>
						<TextField
							margin="dense"
							variant="outlined"
							id="ClienteID"
							name="ClienteID"
							value={getSettingValue("ClienteID")}
							className={classes.settingOption}
							onChange={e => handleChangeSetting(e, "ClienteID")}
						/>
						</Paper>
					)}
				/>

				<Paper className={classes.paper}>
					<TextField
						id="api-token-setting"
						label="Token Api"
						margin="dense"
						variant="outlined"
						fullWidth
						value={settings && settings.length > 0 && getSettingValue("userApiToken")}
					/>
					<CopyToClipboard content={settings && settings.length > 0 && getSettingValue("userApiToken")} color="white" />
				</Paper>

			</Container>
		</div>
	);
};

export default Settings;
