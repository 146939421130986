import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    CircularProgress,
    TextField,
    Switch,
    FormControlLabel,
    Tabs,
    Tab,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import IntegracaoSelect from "../IntegracaoSelect";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    multTimeFieldLine: {
        display: "flex",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(2),
        },
    },
    timeLabel: {
        fontSize: "0.9rem",
        marginRight: theme.spacing(1),
    },
    btnWrapper: {
        position: "relative",
        marginLeft: theme.spacing(2),
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const SessionSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
    const classes = useStyles();
    const initialState = {
        name: "",
        greetingMessage: "",
        farewellMessage: "",
        outServiceMessage: "",
        openingHours: "",
        closingHours: "",
        autoCloseMinutes: null,
        autoCloseMessage: "",
        token: "",
        ratingMessage: "",
        useoutServiceMessage: false,
        closeMinutesNoReply: null,
        closeMessageNoReply: "",
        ImportMessage: false,
        isDefault: false,
        integracaoId: null,
    };
    const [whatsApp, setWhatsApp] = useState(initialState);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [integracoes, setIntegracoes] = useState([]);

    useEffect(() => {
        const fetchSession = async () => {
            if (!whatsAppId) return;
    
            try {
                const { data } = await api.get(`whatsapp/${whatsAppId}`);
                setWhatsApp(prevState => ({
                    ...prevState,
                    ...data,
                    integracaoId: data.integracaoId || prevState.integracaoId,
                }));
    
                const whatsQueueIds = data.queues?.map(queue => queue.id);
                setSelectedQueueIds(whatsQueueIds);
            } catch (err) {
                toastError(err);
            }
        };
    
        const fetchIntegracoes = async () => {
            try {
                const { data } = await api.get("/integracoes");
                setIntegracoes(data);
            } catch (err) {
                toastError(err);
            }
        };
    
        fetchSession();
        fetchIntegracoes();
    }, [whatsAppId]);

    const handleSaveWhatsApp = async values => {
        const autoCloseMinutes = values.autoCloseMinutes === "" ? null : values.autoCloseMinutes;
        const closeMinutesNoReply = values.closeMinutesNoReply === "" ? null : values.closeMinutesNoReply;
        const closeMessageNoReply = values.closeMessageNoReply === "" ? null : values.closeMessageNoReply;
        const selectedIntegracaoId = values.integracaoId;
        const whatsappData = {
            ...values,
            queueIds: selectedQueueIds,
            autoCloseMinutes: autoCloseMinutes,
            closeMinutesNoReply: closeMinutesNoReply,
            closeMessageNoReply: closeMessageNoReply,
            integracoesId: selectedIntegracaoId,
        };
    
        try {
            if (whatsAppId) {
                
                await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
            } else {
                await api.post("/whatsapp", whatsappData);
            }
            toast.success(i18n.t("whatsappModal.success"));
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };
    
    const handleClose = () => {
        onClose();
        setWhatsApp(initialState);
    };

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };

    const handleIntegracaoChange = (selectedId, setFieldValue) => {
        setFieldValue("integracaoId", selectedId);
        setWhatsApp(prevState => ({
            ...prevState,
            integracaoId: selectedId,
        }));
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>
                    {whatsAppId
                        ? i18n.t("whatsappModal.title.edit")
                        : i18n.t("whatsappModal.title.add")}
                </DialogTitle>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Tab 1" value={0} />
                    <Tab label="Tab 2" value={1} />
                </Tabs>
                <Formik
                    initialValues={whatsApp}
                    enableReinitialize={true}
                    validationSchema={SessionSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveWhatsApp(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, touched, errors, isSubmitting, setFieldValue }) => (
                        <Form>
                            <DialogContent dividers>
                                {activeTab === 0 && (
                                    <div>
                                        <div className={classes.multFieldLine}>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.name")}
                                                autoFocus
                                                name="name"
                                                error={touched.name && Boolean(errors.name)}
                                                helperText={touched.name && errors.name}
                                                variant="outlined"
                                                margin="dense"
                                                className={classes.textField}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        as={Switch}
                                                        color="primary"
                                                        name="isDefault"
                                                        checked={values.isDefault}
                                                    />
                                                }
                                                label={i18n.t("whatsappModal.form.default")}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("queueModal.form.greetingMessage")}
                                                type="greetingMessage"
                                                multiline
                                                minRows={5}
                                                fullWidth
                                                name="greetingMessage"
                                                error={
                                                    touched.greetingMessage && Boolean(errors.greetingMessage)
                                                }
                                                helperText={
                                                    touched.greetingMessage && errors.greetingMessage
                                                }
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.farewellMessage")}
                                                type="farewellMessage"
                                                multiline
                                                minRows={5}
                                                fullWidth
                                                name="farewellMessage"
                                                error={
                                                    touched.farewellMessage && Boolean(errors.farewellMessage)
                                                }
                                                helperText={
                                                    touched.farewellMessage && errors.farewellMessage
                                                }
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.outServiceMessage")}
                                                type="outServiceMessage"
                                                multiline
                                                minRows={5}
                                                maxRows={5}
                                                fullWidth
                                                name="outServiceMessage"
                                                error={
                                                    touched.outServiceMessage &&
                                                    Boolean(errors.outServiceMessage)
                                                }
                                                helperText={
                                                    touched.outServiceMessage && errors.outServiceMessage
                                                }
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>

                                        <div className={classes.multFieldLine}>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.autoCloseMinutes")}
                                                name="autoCloseMinutes"
                                                type="number"
                                                error={touched.autoCloseMinutes && Boolean(errors.autoCloseMinutes)}
                                                helperText={touched.autoCloseMinutes && errors.autoCloseMinutes}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                value={values.autoCloseMinutes === null ? "" : values.autoCloseMinutes}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        as={Switch}
                                                        color="primary"
                                                        name="ImportMessage"
                                                        checked={values.ImportMessage}
                                                    />
                                                }
                                                label={i18n.t("whatsappModal.form.ImportMessage")}
                                            />
                                        </div>

                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.autoCloseMessage")}
                                                name="autoCloseMessage"
                                                multiline
                                                minRows={5}
                                                fullWidth
                                                error={touched.autoCloseMessage && Boolean(errors.autoCloseMessage)}
                                                helperText={touched.autoCloseMessage && errors.autoCloseMessage}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>

                                        <div className={classes.multFieldLine}>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.closeMinutesNoReply")}
                                                name="closeMinutesNoReply"
                                                type="number"
                                                error={touched.closeMinutesNoReply && Boolean(errors.closeMinutesNoReply)}
                                                helperText={touched.closeMinutesNoReply && errors.closeMinutesNoReply}
                                                variant="outlined"
                                                margin="dense"
                                                fullWidth
                                                value={values.closeMinutesNoReply === null ? "" : values.closeMinutesNoReply}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("whatsappModal.form.closeMessageNoReply")}
                                                name="closeMessageNoReply"
                                                multiline
                                                minRows={5}
                                                fullWidth
                                                error={touched.closeMessageNoReply && Boolean(errors.closeMessageNoReply)}
                                                helperText={touched.closeMessageNoReply && errors.closeMessageNoReply}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </div>

                                        <div className={classes.multTimeFieldLine}>
                                            <label className={classes.timeLabel}>{i18n.t("whatsappModal.form.openingHours")}</label>
                                            <Field
                                                as={TextField}
                                                type="time"
                                                name="openingHours"
                                                error={touched.openingHours && Boolean(errors.openingHours)}
                                                helperText={touched.openingHours && errors.openingHours}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                            <label className={classes.timeLabel}>{i18n.t("whatsappModal.form.closingHours")}</label>
                                            <Field
                                                as={TextField}
                                                type="time"
                                                name="closingHours"
                                                error={touched.closingHours && Boolean(errors.closingHours)}
                                                helperText={touched.closingHours && errors.closingHours}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Field
                                                        as={Switch}
                                                        color="primary"
                                                        name="useoutServiceMessage"
                                                        checked={values.useoutServiceMessage}
                                                    />
                                                }
                                                label={i18n.t("whatsappModal.form.useoutServiceMessage")}
                                            />
                                        </div>

                                        <div>
                                            <Field
                                                as={TextField}
                                                label={i18n.t("queueModal.form.ratingMessage")}
                                                type="ratingMessage"
                                                multiline
                                                rows={4}
                                                fullWidth
                                                name="ratingMessage"
                                                error={
                                                touched.ratingMessage && Boolean(errors.ratingMessage)
                                                }
                                                helperText={touched.ratingMessage && errors.ratingMessage}
                                                variant="outlined"
                                                margin="dense"
                                            />
                                            </div>

                                        <QueueSelect
                                                selectedQueueIds={selectedQueueIds}
                                                onChange={selectedIds => setSelectedQueueIds(selectedIds)}
                                            />
                                   </div>
                                )}
                               {activeTab === 1 && (
                                    <div>
                                        {/* Conteúdo da segunda aba */}
                                        <IntegracaoSelect
                                            selectedIntegracaoId={values.integracaoId}
                                            onChange={(selectedId) => handleIntegracaoChange(selectedId, setFieldValue)}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("whatsappModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {whatsAppId
                                        ? i18n.t("whatsappModal.buttons.okEdit")
                                        : i18n.t("whatsappModal.buttons.okAdd")}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default React.memo(WhatsAppModal);
