import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { toast } from "react-toastify";
import axios from "axios";
import api from "../../services/api";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { i18n } from "../../translate/i18n";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import useWhatsApps from "../../hooks/useWhatsApps";
import { useTheme } from "@material-ui/core";

import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";


import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";

import NewTicketModal from "../../components/ContactModalApi";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(20),
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: "40%",
  },
  button: {
    marginBottom: theme.spacing(2),
    width: "40%",
  },
  formControl: {
    width: "40%",
    marginBottom: theme.spacing(2),
  },
  emojiButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const SendMessage = () => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [connectionNumber, setConnectionNumber] = useState("");
  const [token, setToken] = useState("");
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const { loadingWhatsapps, whatsApps } = useWhatsApps();
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [selectedEmoji, setSelectedEmoji] = useState(""); // Adicionar estado para o emoji selecionado
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [selectedContactPhoneNumber, setSelectedContactPhoneNumber] = useState(""); // Adiciona um estado para armazenar o número de telefone do contato selecionado
  const theme = useTheme();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await api.get("/settings/token");
        setToken(response.data.token);
      } catch (error) {
        toast.error("Erro ao obter o token.");
      }
    };
    fetchToken();
  }, []);

  const handleSubmit = async () => {
    try {
      if (!phoneNumber && !selectedContactPhoneNumber) {
        toast.error("Por favor, insira um número de telefone.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}api/messages/send`,
        {
          number: phoneNumber || selectedContactPhoneNumber,
          body: messageBody + selectedEmoji, // Adicionar o emoji selecionado ao corpo da mensagem
          whatsappId: connectionNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.error === "SUCCESS") {
        toast.success("Mensagem enviada com sucesso!", {
          autoClose: 10000,
        });
      } else {
        toast.error("Erro ao enviar a mensagem.");
      }
    } catch (error) {
      toast.error("Erro ao enviar a mensagem.");
    }
  };

  return (
    <Container className={classes.container}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        color={theme.mode === "dark" ? "textPrimary" : "black"}
      >
        Enviar Mensagem
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => setNewTicketModalOpen(true)}
      >
       Selecionar Contato
      </Button>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
        onContactSelect={(phoneNumber) => setSelectedContactPhoneNumber(phoneNumber)} // Recebe o número de telefone do contato selecionado
      />
      <TextField
        className={classes.textField}
        label={i18n.t("apiMessage.fieldNumeroContato")}
        variant="outlined"
        value={phoneNumber || selectedContactPhoneNumber} // Preenche com phoneNumber se estiver preenchido, senão preenche com o número de telefone do contato selecionado
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <TextField
        className={classes.textField}
        label={i18n.t("apiMessage.fieldCorpoMensagem")}
        variant="outlined"
        multiline
        rows={4}
        value={messageBody}
        onChange={(e) => setMessageBody(e.target.value)}
        InputProps={{
          endAdornment: (
            <IconButton
              className={classes.emojiButton}
              onClick={(e) => setEmojiAnchorEl(e.currentTarget)}
            >
              <InsertEmoticonIcon />
            </IconButton>
          ),
        }}
      />
      <Popover
        open={Boolean(emojiAnchorEl)}
        anchorEl={emojiAnchorEl}
        onClose={() => setEmojiAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.type === "dark" ? "#424242" : "#ffffff",
          },
        }}
      >
        <Picker
          set="apple"
          theme={theme.palette.type === 'dark' ? 'dark' : 'light'}
          onSelect={(emoji) => {
            const updatedMessageBody = messageBody + emoji.native;
            setMessageBody(updatedMessageBody);
            setEmojiAnchorEl(null);
          }}
        />
      </Popover>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{i18n.t("apiMessage.fieldConnectionLabel")}</InputLabel>
        <Select
          value={selectedWhatsapp}
          onChange={(e) => {
            setSelectedWhatsapp(e.target.value);
            setConnectionNumber(e.target.value);
          }}
          label={i18n.t("apiMessage.fieldConnectionPlaceholder")}
          fullWidth
        >
          {whatsApps.map((whatsapp) => (
            <MenuItem key={whatsapp.id} value={whatsapp.id}>
              {whatsapp.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Enviar Mensagem
      </Button>
    </Container>
  );
};

export default SendMessage;
