const rules = {
	user: {
		static: [
			"tarefas-page:DeleteTarefa", //Pode deletar as tarefas
			"tarefas-page:AddTarefa", //Pode adicionar as tarefas
			"user-modal:editProfile",
			"contacts-page:CreateContact",
			"contacts-page:EditContact"
		],
	},

	supervisor: {
		static: [
			//"connections-page:view"
			"user-modal:editProfile",
			"contacts-page:CreateContact",
			"contacts-page:EditContact",

		],
	},

	master: {
		static: [
			//"connections-page:view"
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile", //Edita Perfil
			"user-modal:editQueues", //Edita Filas
			"ticket-options:deleteTicket", //Deleta Ticket
			"ticket-options:transferWhatsapp", 
			"contacts-page:deleteContact", //Deleta Contatos
			"contacts-page:ImportContact", //importa Contatos
			"contacts-page:CreateContact", //Cria Contatos
			"connections-page:view", //Pagina de Conexões
			"connections-page:AddConnection", //Pode Adicionar Conexoes Whatsapp
			"connections-page:EditWhatsApp", //Pode Editar Conexoes Whatsapp
			"connections-page:DeleteWhatsApp", //Pode Deletar Conexoes Whatsapp
			"contacts-page:ExportContact", //Pode Exportar Contatos
			"chat-control:EnableAssinatura", //Ver & Habilitar/Desabilitar Assinatura
			"setting-control:editqtdconexoes", //Pode editar quantidade de conexões permitidas
			"setting-control:editqtdusuarios", //Pode editar quantidade de usuarios permitidos.
			"setting-control:ClienteID",
			"setting-control:tokenAssas",
			"tarefas-page:VerinformacoesTarefa", //Consegue visualizar usuario que criou a tarefa
			"tarefas-page:DeleteTarefa", //Pode deletar as tarefas
			"tarefas-page:AddTarefa", //Pode adicionar as tarefas
			"integracoes-page:CreateIntegracao", //Pode Criar Integrações
			"integracoes-page:EditIntegracao", //Pode Editar Integrações
			"integracoes-page:deleteIntegracao", //Pode Deletar Integrações
			"contacts-page:EditContact",


		],
	},

	admin: {
		static: [
			"drawer-admin-items:view",
			"tickets-manager:showall",
			"user-modal:editProfile", //Edita Perfil
			"user-modal:editQueues", //Edita Filas
			"ticket-options:deleteTicket", //Deleta Ticket
			"ticket-options:transferWhatsapp", 
			"contacts-page:deleteContact", //Deleta Contatos
			"contacts-page:ImportContact", //importa Contatos
			"contacts-page:CreateContact", //Cria Contatos
			"connections-page:view", //Pagina de Conexões
			"connections-page:AddConnection", //Pode Adicionar Conexoes Whatsapp
			"connections-page:EditWhatsApp", //Pode Editar Conexoes Whatsapp
			"connections-page:DeleteWhatsApp", //Pode Deletar Conexoes Whatsapp
			"contacts-page:ExportContact", //Pode Exportar Contatos
			"chat-control:EnableAssinatura", //Ver & Habilitar/Desabilitar Assinatura

		],
	},
};

export default rules;
