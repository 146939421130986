import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import ForgotPassword from "../pages/ForgotPassword/";
import ResetPassword from "../pages/NewPassword/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import Tarefas from "../pages/Tarefas/";
import Integracoes from "../pages/Integracao/";


import api from "../../src/services/api";

import EnviarMensagem from "../pages/EnviarMensagem/";
import Tags from "../pages/Tags/";
import Kanban from "../pages/Kanban";
import Financeiro from "../pages/Financeiro/";
import Relatorios from "../pages/Relatorios/";
import RelatTicketPorUsuario from "../pages/RelatTicketPorUsuario/";

import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";

const Routes = () => {
  const [isSignupEnabled, setIsSignupEnabled] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSignupSetting = async () => {
      try {
        const response = await api.get('/settings/check-user-creation-enabled');
        setIsSignupEnabled(response.data.isUserCreationEnabled);
      } catch (error) {
        console.error('Erro ao buscar configuração de cadastro', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSignupSetting();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          {!isSignupEnabled && <Redirect from="/signup" to="/login" />}
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/recover-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:token" component={ResetPassword} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} isPrivate />
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/quickAnswers" component={QuickAnswers} isPrivate />
              <Route exact path="/Settings" component={Settings} isPrivate />
              <Route exact path="/Queues" component={Queues} isPrivate />
              <Route exact path="/Enviarmensagem" component={EnviarMensagem} isPrivate />
              <Route exact path="/Tarefas" component={Tarefas} isPrivate />
              <Route exact path="/Integracoes" component={Integracoes} isPrivate />
              <Route exact path="/relatorio-tickets-por-fila" component={Relatorios} isPrivate />
              <Route exact path="/relatorio-tickets-por-usuario" component={RelatTicketPorUsuario} isPrivate />
              <Route 
                  exact
                  path="/kanban"
                  component={Kanban}
                  isPrivate
                />
                <Route
                  exact
                  path="/financeiro"
                  component={Financeiro}
                  isPrivate
                />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
