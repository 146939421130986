import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const IntegracaoSelect = ({ selectedIntegracaoId, onChange }) => {
  const classes = useStyles();
  const [integracoes, setIntegracoes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState(null); // Inicializar como null

  useEffect(() => {
    const fetchIntegracoes = async () => {
      try {
        const { data } = await api.get("/integracoes");
        setIntegracoes(data);
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    };

    fetchIntegracoes();
  }, []);

  useEffect(() => {
    setSelectedId(selectedIntegracaoId); // Definir como selectedIntegracaoId diretamente
  }, [selectedIntegracaoId]);

  const handleChange = (e) => {
    const selectedId = e.target.value;
    setSelectedId(selectedId);
    onChange(selectedId === "null" ? null : selectedId); // Passar null se "emptyOption" for selecionado
  };

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>{i18n.t("integracaoSelect.inputLabel")}</InputLabel>
        <Select
          value={selectedId === null ? "null" : selectedId} // Definir "null" se o valor for null
          onChange={handleChange}
          label={i18n.t("integracaoSelect.inputLabel")}
          disabled={loading}
        >
          {loading && (
            <MenuItem value="">{i18n.t("integracaoSelect.loadingOption")}</MenuItem>
          )}
          {!loading && (
            <MenuItem value="null">{i18n.t("integracaoSelect.emptyOption")}</MenuItem>
          )}
          {!loading &&
            integracoes.map((integracao) => (
              <MenuItem key={integracao.id} value={integracao.id}>
                {integracao.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default IntegracaoSelect;
