import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper, TextField, Button, Typography, useTheme, Grid, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(8, 8, 3),
        backgroundColor: theme.palette.type === "dark" ? theme.palette.background.default : theme.palette.background.paper,
        minHeight: "100vh", // Garante que o conteúdo ocupe toda a altura da tela
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(1),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const PasswordRecovery = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [email, setEmail] = useState("");

    const handleRecovery = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post("/recover-password", { email });
            if (response.status === 200) {
                toast.success(i18n.t("passwordRecovery.success"));
            } else {
                toast.error(i18n.t("passwordRecovery.error"));
            }
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="xs">
                    <Typography component="h1" variant="h5" color={theme.mode === 'dark' ? 'textPrimary' : 'black'}>
                        {i18n.t("passwordRecovery.title")}
                    </Typography>
                    <form className={classes.form} onSubmit={handleRecovery}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={i18n.t("passwordRecovery.emailLabel")}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {i18n.t("passwordRecovery.recoverButton")}
                        </Button>

                        <Grid container>

                        <Grid item xs>
                        <Link
                            href="#"
                            variant="body2"
                            component={RouterLink}
                            to="/login"
                        >
                            {i18n.t("signup.buttons.login")}
                        </Link>
                        </Grid>

                    </Grid>
                    </form>
            </Container>
        </div>
    );
};

export default PasswordRecovery;
