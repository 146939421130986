import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Modal from "@material-ui/core/Modal";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_PAYMENTS") {
    return action.payload;
  }
  return state;
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    outline: "none",
    borderRadius: 8,
  },
  qrCodeImage: {
    maxWidth: "100%",
    height: "auto",
  },
}));

const Payments = () => {
  const classes = useStyles();

  const [payments, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [qrCodeData, setQrCodeData] = useState("");
  const [tokenAsaas, setTokenAsaas] = useState("");

  useEffect(() => {
    const fetchTokenAsaas = async () => {
      try {
        const response = await api.get("/settings/token-asaas");
        setTokenAsaas(response.data.tokenAsaas);
      } catch (error) {
        console.error('Erro ao obter o token do Asaas:', error);
      }
    };

    fetchTokenAsaas();
  }, []);

  const getPixQrCode = async (paymentId) => {
    try {
      const response = await api.get(`/payments/${paymentId}/qr-code`);
      if (response.status === 200) {
        return response.data.qrCodeUrl;
      } else {
        console.error('Erro ao obter o QR code:', response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Erro ao obter o QR code:', error);
      return null;
    }
  };

  const handleAction = async (payment) => {
    try {
      const qrCodeUrl = await getPixQrCode(payment.paymentId, tokenAsaas);
      if (qrCodeUrl) {
        setQrCodeData(qrCodeUrl);
        setOpenModal(true);
      } else {
        toastError("Erro ao obter os dados do QR code");
      }
    } catch (error) {
      console.error('Erro em handleAction:', error);
      toastError(error);
    }
  };

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setLoading(true);
        const response = await api.get("/payments", {
          params: { searchParam },
        });
        dispatch({ type: "LOAD_PAYMENTS", payload: response.data });
        setLoading(false);
      } catch (error) {
        toastError(error);
        setLoading(false);
      }
    };

    fetchPayments();
  }, [searchParam]);

  const handleCloseModal = () => {
    setOpenModal(false);
    setQrCodeData("");
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>Pagamentos</Title>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Valor</TableCell>
              <TableCell align="center">Data Vencimento</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment) => (
              <TableRow key={payment.id}>
                <TableCell align="center">{payment.id}</TableCell>
                <TableCell align="center">{payment.value}</TableCell>
                <TableCell align="center">{moment(payment.dueDate).format("DD/MM/YYYY")}</TableCell>
                <TableCell align="center">
                  {payment.status === "RECEIVED" || payment.status === "RECEIVED_IN_CASH" ? (
                    "Pago"
                  ) : payment.status === "PENDING" ? (
                    "Pendente"
                    ) : payment.status === "OVERDUE" ? (
                      "Vencido"
                    ) : payment.status === "REFUNDED" ? (
                    "Extornado"
                  ) : (
                    payment.status
                  )}
                </TableCell>
                <TableCell align="center">
                  {payment.status === "PENDING" && (
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => handleAction(payment)}
                    >
                      PAGAR
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        className={classes.modal}
      >
        <div className={classes.modalContent}>
          {qrCodeData && <img src={`data:image/png;base64,${qrCodeData}`} alt="QR Code" className={classes.qrCodeImage} />}
        </div>
      </Modal>
    </MainContainer>
  );
};

export default Payments;
