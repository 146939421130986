import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper, TextField, Button, Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
    backgroundColor: theme.palette.type === "dark" ? theme.palette.background.default : theme.palette.background.paper,
    minHeight: "100vh",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post("/reset-password", { token, password });
      if (response.status === 200) {
        toast.success(i18n.t("resetPassword.success"));
      } else {
        toast.error(i18n.t("resetPassword.error"));
      }
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            {i18n.t("resetPassword.title")}
          </Typography>
          <form className={classes.form} onSubmit={handleResetPassword}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label={i18n.t("resetPassword.passwordLabel")}
              name="password"
              type="password"
              autoComplete="new-password"
              autoFocus
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmPassword"
              label={i18n.t("resetPassword.confirmPasswordLabel")}
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {i18n.t("resetPassword.resetButton")}
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default ResetPassword;
