import { useState, useEffect } from "react";
import api from "../../services/api";

const useMessages = () => {
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [sentCount, setSentCount] = useState(0);
    const [receivedCount, setReceivedCount] = useState(0);

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const { data } = await api.get("/messages");
                const allMessages = data.messages;
                const sentMessages = allMessages.filter(message => message.fromMe === true);
                const receivedMessages = allMessages.filter(message => message.fromMe === false);
                setMessages(allMessages);
                setSentCount(sentMessages.length);
                setReceivedCount(receivedMessages.length);
                setLoading(false);

            } catch (err) {
                setLoading(false);
                //console.error("Error fetching messages:", err);
            }
        };

        fetchMessages();
        return () => {};
    }, []);

    return { messages, loading, sentCount, receivedCount };
};

export default useMessages;
