import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const IntegracaoSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório"),
  tipo: Yup.string().required("Obrigatório"),
  urlWebhook: Yup.string().nullable(), // Agora o campo pode ser nulo
  urlbot: Yup.string().nullable(), // Agora o campo pode ser nulo
  botname: Yup.string().nullable() // Agora o campo pode ser nulo
});

const IntegracaoModal = ({ open, onClose, integracao, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [integracaoId, setIntegracaoId] = useState(null);

  useEffect(() => {
    if (integracao && integracao.id) {
      setIntegracaoId(integracao.id);
    }
  }, [integracao]);

  const handleClose = () => {
    onClose();
  };

  const handleSaveIntegracao = async (values) => {
    try {
      if (integracaoId) {
        await api.put(`/integracoes/${integracaoId}`, values);
      } else {
        await api.post("/integracoes", values);
      }
      toast.success(i18n.t("integracaoModal.success"));
      if (typeof reload === "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const initialValues = {
    name: '',
    tipo: '',
    urlWebhook: '',
    urlbot: '', // Adicione valores padrão para as novas colunas
    botname: '' // Adicione valores padrão para as novas colunas
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {integracaoId
            ? `${i18n.t("integracaoModal.title.edit")}`
            : `${i18n.t("integracaoModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={integracao || initialValues}
          enableReinitialize={true}
          validationSchema={IntegracaoSchema}
          onSubmit={(values, actions) => {
            if (!values.name) {
              toast.error("Por favor, preencha o campo 'name'");
              return;
            }
            setTimeout(() => {
              handleSaveIntegracao(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("integracaoModal.form.name")}
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    fullWidth
                    select
                    label={i18n.t("integracaoModal.form.tipo")}
                    name="tipo"
                    error={touched.tipo && Boolean(errors.tipo)}
                    helperText={touched.tipo && errors.tipo}
                    variant="outlined"
                    margin="dense"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value=""></option>
                    <option value="n8n">n8n</option>
                    <option value="botpress">botpress</option>
                  </Field>
                </div>
                {values.tipo === 'botpress' && (
  <>
    <div className={classes.multFieldLine}>
      <Field
        as={TextField}
        fullWidth
        label={i18n.t("integracaoModal.form.urlbot")}
        name="urlbot"
        error={touched.urlbot && Boolean(errors.urlbot)}
        helperText={touched.urlbot && errors.urlbot}
        variant="outlined"
        margin="dense"
      />
    </div>
    <div className={classes.multFieldLine}>
      <Field
        as={TextField}
        fullWidth
        label={i18n.t("integracaoModal.form.botname")}
        name="botname"
        error={touched.botname && Boolean(errors.botname)}
        helperText={touched.botname && errors.botname}
        variant="outlined"
        margin="dense"
      />
    </div>
  </>
)}
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    fullWidth
                    label={i18n.t("integracaoModal.form.urlWebhook")}
                    name="urlWebhook"
                    error={touched.urlWebhook && Boolean(errors.urlWebhook)}
                    helperText={touched.urlWebhook && errors.urlWebhook}
                    variant="outlined"
                    margin="dense"
                    // Renderiza o campo somente se o tipo for 'n8n'
                    style={{ display: values.tipo === 'n8n' ? 'block' : 'none' }}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary" overlap="rectangular"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("integracaoModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary" overlap="rectangular"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {integracaoId
                    ? `${i18n.t("integracaoModal.buttons.okEdit")}`
                    : `${i18n.t("integracaoModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default IntegracaoModal;
