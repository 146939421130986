import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { QueryClient, QueryClientProvider } from "react-query";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";

import whatsBackgroundLight from "../src/assets/wa-background.png";
import whatsBackgroundDark from "../src/assets/wadark-background.png";

const queryClient = new QueryClient();

const App = () => {
  const [locale, setLocale] = useState();

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const preferredTheme = window.localStorage.getItem("preferredTheme");
  const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");

  const colorMode = React.useMemo(
    () => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        },
    }),
    []
);

const theme = createTheme(
  {
      scrollbarStyles: {
          "&::-webkit-scrollbar": {
              width: '8px',
              height: '8px',
          },
          "&::-webkit-scrollbar-thumb": {
              boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
              backgroundColor: "#e8e8e8",
          },
      },
      scrollbarStylesSoft: {
          "&::-webkit-scrollbar": {
              width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
              backgroundColor: mode === "light" ? "#F3F3F3" : "#333333",
          },
      },
      palette: {
          type: mode,
          primary: { main: "#007aff" },
          textPrimary: mode === "light" ? "#007aff" : "#FFFFFF",
          borderPrimary: mode === "light" ? "#007aff" : "#FFFFFF",
          dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
          light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
          tabHeaderBackground: mode === "light" ? "#EEE" : "#666",
          optionsBackground: mode === "light" ? "#fafafa" : "#333",
          fancyBackground: mode === "light" ? "#fafafa" : "#333",
          total: mode === "light" ? "#fff" : "#222",
          messageIcons: mode === "light" ? "grey" : "#F3F3F3",
          inputBackground: mode === "light" ? "#FFFFFF" : "#333",
          barraSuperior: "#007aff",
          lightBackground: "#fafafa", // Nova cor para o tema light
          darkBackground: "#1A1A1A3D", // Nova cor para o tema dark (por exemplo, um cinza escuro)
      },
      backgroundImage: `url(${mode === 'dark' ? whatsBackgroundDark : whatsBackgroundLight})`,
      mode,
  },
  locale
);

useEffect(() => {
  const i18nlocale = localStorage.getItem("i18nextLng");
  const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

  if (browserLocale === "ptBR") {
      setLocale(ptBR);
  }
}, []);

useEffect(() => {
  window.localStorage.setItem("preferredTheme", mode);
}, [mode]);

return (
  <ColorModeContext.Provider value={{ colorMode }}>
      <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
              <Routes />
          </QueryClientProvider>
      </ThemeProvider>
  </ColorModeContext.Provider>
);
};

export default App;
