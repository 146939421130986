import React, { useState, useEffect } from "react";
import { makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, Card, CardContent, Grid, Button } from "@material-ui/core";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; 
import api from "../../services/api";
import * as XLSX from "xlsx"; 

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    padding: theme.spacing(2),
  },
  filterCard: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  datePicker: {
    marginBottom: theme.spacing(2),
  },
  exportButton: {
    marginBottom: theme.spacing(2),
  },
}));

const TicketReport = () => {
  const classes = useStyles();
  const [ticketData, setTicketData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);

  const fetchTicketData = async (startDate, endDate) => {
    try {
      const response = await api.get("/ticket-report", {
        params: {
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
        }
      });
  
      if (response.data && response.data.length > 0) {
        setTicketData(response.data);
      } else {
        setTicketData([]);
      }
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  };
  
  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  useEffect(() => {
    const [startDate, endDate] = dateRange;
    fetchTicketData(startDate, endDate);
  }, [dateRange]); // Reload data when date range changes

  const handleExport = () => {
    const dataToExport = ticketData.map(({ queueName, ticketCount, ticketsInProcess, ticketsWaiting }) => ({
      "Fila": queueName,
      "Quantidade de Tickets": ticketCount,
      "Em Atendimento": ticketsInProcess || 0,
      "Aguardando Atendimento": ticketsWaiting || 0
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");
    XLSX.writeFile(workbook, "Tickets_Por_Filas.xlsx");
  };

  return (
    <Paper className={classes.mainPaper}>
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <Card className={classes.filterCard}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}> {/* Ocupa metade do espaço disponível */}
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale="pt-BR">
                    <DatePicker
                      className={classes.datePicker}
                      label="Data Inicial"
                      value={dateRange[0]}
                      onChange={(newDate) => handleDateChange([newDate, dateRange[1]])}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}> {/* Ocupa metade do espaço disponível */}
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale="pt-BR">
                    <DatePicker
                      className={classes.datePicker}
                      label="Data Final"
                      value={dateRange[1]}
                      onChange={(newDate) => handleDateChange([dateRange[0], newDate])}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" className={classes.exportButton}>
        <Button variant="contained" color="primary" onClick={handleExport}>
          Exportar
        </Button>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fila</TableCell>
            <TableCell>Quantidade de Tickets</TableCell>
            <TableCell>Em Atendimento</TableCell>
            <TableCell>Aguardando Atendimento</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ticketData.map((row) => (
            <TableRow key={row.queueName}>
              <TableCell>{row.queueName}</TableCell>
              <TableCell>{row.ticketCount}</TableCell>
              <TableCell>{row.ticketsInProcess}</TableCell>
              <TableCell>{row.ticketsWaiting}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default TicketReport;
