import React, { useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import useAuth from "../../hooks/useAuth.js";
import { Can } from "../../components/Can";
import ConfirmationModal from "../../components/ConfirmationModal";
import soundFile from "../../assets/wrong-answer-129254.mp3";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  form: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    maxWidth: "100%",
    margin: "0 auto",
  },
  textField: {
    flex: 1,
    marginRight: theme.spacing(2),
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  button: {
    marginLeft: "auto",
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  taskCard: {
    position: "relative",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  deleteButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const TaskList = ({ tasks, onDelete }) => {
  const classes = useStyles();
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const taskListRef = useRef();

  const soundRef = useRef(new Audio(soundFile));

  const { user } = useContext(AuthContext); // Obtenha o usuário do contexto

  const handleOpenConfirmationModal = (taskId) => {
    setTaskIdToDelete(taskId);
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setTaskIdToDelete(null);
    setConfirmationModalOpen(false);
  };

  const handleDelete = async () => {
    try {
      await api.delete(`/tasks/${taskIdToDelete}`);
      onDelete(taskIdToDelete);
      toast.success(i18n.t("tasks.toasts.deleted"));
    } catch (error) {
      console.error(error);
      toast.error(i18n.t("tasks.toasts.error"));
    }
    handleCloseConfirmationModal();
  };

  return (
    <div ref={taskListRef} style={{ overflowY: "auto", maxHeight: "70vh" }}>
      {tasks.map((task) => (
        <Paper key={task.id} className={classes.taskCard}>
          <Typography variant="body1"><strong>Tarefa:</strong> {task.name}</Typography>
          <Typography variant="body1"><strong>Criado em:</strong> {new Date(task.createdAt).toLocaleString()}</Typography>
          <Can
            role={user.profile}
            perform="tarefas-page:VerinformacoesTarefa"
            yes={() => (
              <Typography variant="body1"><strong>Por:</strong> {task.user.name}</Typography>
            )}
          />
          <Can
            role={user.profile}
            perform="tarefas-page:DeleteTarefa"
            yes={() => (
              <IconButton className={classes.deleteButton} onClick={() => handleOpenConfirmationModal(task.id)}>
                <DeleteIcon />
              </IconButton>
            )}
          />
        </Paper>
      ))}
      <ConfirmationModal
        open={confirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDelete}
        title={i18n.t("tasks.confirmDeleteMessage")}
      >
        {i18n.t("tags.confirmationModal.deleteMessage")}
      </ConfirmationModal>

    </div>
  );
};

const CreateTask = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const [tasks, setTasks] = useState([]);
  const [name, setName] = useState("");

  const soundRef = useRef(new Audio(soundFile)); // Declare a referência soundRef

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await api.get(`/tasks?userId=${user.id}`);
        const fetchedTasks = response.data;
        setTasks(fetchedTasks);
      } catch (error) {
        console.error(error);
        toast.error(i18n.t("tasks.toasts.error"));
      }
    };

    fetchTasks();
  }, [user.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post("/tasks", { name, userId: user.id });
      toast.success(i18n.t("tasks.toasts.created"));
      setName("");
      const response = await api.get(`/tasks?userId=${user.id}`);
      setTasks(response.data);
      soundRef.current.play();
    } catch (err) {
      console.error(err);
      toast.error(i18n.t("tasks.toasts.error"));
    }
  };

  const handleTaskDelete = (deletedTaskId) => {
    setTasks(tasks.filter(task => task.id !== deletedTaskId));
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>{i18n.t("tasks.title")}</Title>
      </MainHeader>
      <Paper className={classes.mainPaper} elevation={0} style={{ backgroundColor: "transparent" }}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            className={classes.textField}
            label={i18n.t("tasks.labels.name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            required
          />
          <Can
            role={user.profile}
            perform="tarefas-page:AddTarefa"
            yes={() => (
              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
              >
                {i18n.t("tasks.buttons.create")}
              </Button>
            )}
          />
        </form>
      </Paper>
      <TaskList tasks={tasks} onDelete={handleTaskDelete} />
    </MainContainer>
  );
};

export default CreateTask;
