const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },

        /* 
        / INICIO DA TRADUCAO PARA O LINK
        / ESQUECEU A SENHA
        */

        links: {
          forgotPassword : "Esqueceu a senha?",
        },

        /* 
        / FIM DA TRADUCAO PARA O LINK
        / ESQUECEU A SENHA
        */

        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },

      /* 
      / INSERÇÃO DO BLOCO DE TRADUÇÃO
      / DA PAGINA DE ESQUECEU A SENHA
      */

      passwordRecovery: {
        title: "Recuperar Senha",
        emailLabel: "E-mail",
        recoverButton: "Resetar Senha"
      },

      /* 
      / FIM DO BLOCO DE TRADUÇÃO
      / DA PAGINA DE ESQUECEU A SENHA
      */

      resetPassword: {
        title: "Resetar Senha",
        passwordLabel: "Inserir nova senha",
        confirmPasswordLabel: "Repetir nova senha",
        resetButton: "Alterar Senha"
      },

      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },

      messageVariablesPicker: {
        label: "Variavéis disponíveis",
        vars: {
          firstName: "Primeiro Nome",
          name: "Nome",
          user: "Atendente",
          ticket_id: "Numero do Ticket",
          connection: "Conexão",
          ms: "Saudação",
          queue: "Fila",
          date: "Data",
          hour: "Hora",
          protocol: "Protocolo",
        }
      },

      ticketReport: {
        table: {
          queue: "Fila",
          ticketCount: "Quantidade de Tickets",
        },
      },

      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          },
          contact: {
            title: "Contatos"
          },
          sent: {
            title: "Mensagens Enviadas"
          },
          received: {
            title: "Mensagens Recebidas"
          }
        }
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        toasts: {
          ticketClosed: "Tickets Fechados com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
          closeOpenTickets: "Fechar Tickets Abertos",
          closePendingTickets: "Fechar Tickets Pendentes",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
          id: "Id",
          closeTickets: "Fechar Tickets"
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          name: "Nome",
          default: "Padrão",
          farewellMessage: "Mensagem de despedida",
          useoutServiceMessage: "Mensagem",
          ImportMessage: "Importar mensagens",
          outServiceMessage: "Mensagem fora do expediente",
          closingHours: "Fechamento",
          openingHours: "Abertura",
          autoCloseMinutes: "Encerrar Tickets Abertos após X minutos sem Fila.",
          autoCloseMessage: "Mensagem de encerramento sem Fila.",
          closeMinutesNoReply: "Encerrar Tickets após X minutos sem interação com o atendente.",
          closeMessageNoReply: "Mensagem de Encerramento sem interacao com atendente.",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
          importMessage: "Deseja importas todos os contatos do telefone?",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
          chatbot: "Ignorar Chatbot",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
          ratingMessage: "Mensagem de avaliação",
          token: "Token"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          group: { title: "Grupos" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione uma fila",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },

      apiMessage: {
        fieldConnectionLabel: "Conexão",
        fieldConnectionPlaceholder: "Conexão",
        fieldCorpoMensagem: "Corpo da Mensagem",
        fieldNumeroContato: "Número do Contato",
      },

      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        buttons: {
          accept: "Aceitar",
          closed: "Finalizar",
          reopen: "Reabrir",
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },

      contactModalApi: {
        title: "Selecionar Contato",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          cancel: "Fechar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Tickets",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Filas",
          administration: "Administração",
          users: "Usuários",
          tags: "Tags",
          settings: "Configurações",
          sair: "Sair",
          enviarmensagem: "Enviar Mensagem",
          financeiro: "Financeiro",
          tarefas: "Tarefas",
          integracoes: "Integrações"
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
        singleLabel: "Transferir Contato para a fila"
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },

      integracoes: {
        searchPlaceholder: "Buscar Integração",
        title: "Integrações",
        toasts: {
          deleted: "Integração excluída com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Deletar Mensagem",
          confirm: "Confirmar",
          cancel: "Cancelar",
        },
        table: {
          type: "Tipo",
          id: "Id",
          name: "Nome",
          webhookUrl: "Webhook",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Integração",
        },
      },

      integracaoModal: {
        success: "Sucesso!",
        title:{
          edit: "Editar",
          add: "Adicionar",
        },
        form:{
          name: "Nome",
          tipo: "Tipo",
          urlWebhook: "Webhook",
          botname: "Nome do Bot",
          urlbot: "Url do Bot",
        },
        buttons: {
          cancel: "Cancelar",
          okEdit: "Editar",
          okAdd: "Adicionar",
        }

      },

      integracaoSelect:{
        inputLabel: "Nome",
        placeholder: "Pesquisar...",
        inputLabel: "Integracao",
        emptyOption: "Sem integracao",
      },

      tasks: {
        title: "Tarefas",
        confirmDelete: "Excluir",
        confirmDeleteMessage: "Você tem certeza que quer excluir essa tarefa?",

        labels: {
          name: "Nova Tarefa ",
        },
        buttons: {
          create: "Criar Tarefa",
          confirm: "Deletar",
          cancel: "Cancelar"
        },
        toasts: {
          error: "Erro ao criar tarefa",
          created: "Tarefa Criada com sucesso",
          deleted: "Tarefa removida com sucesso"
        }
      },

      messagesAPI: {
        title: "API",
        textMessage: {
          number: "Número",
          body: "Mensagem",
          token: "Token cadastrado",
        },
        mediaMessage: {
          number: "Número",
          body: "Nome do arquivo",
          media: "Arquivo",
          token: "Token cadastrado",
        },
      },

      businessHours: {
        title: "Horário de Atendimento",
      },

      tagModal:{
        title: {
          edit: "Editar Tag",
          add: "Adicionar Tag"
        },
        form:{
          name: "Nome",
          color: "Cor",
        },
        buttons: {
          cancel: "Cancelar",
          okEdit: "Editar",
          okAdd: "Adicionar"
        }

      },

      copyToClipboard: {
        copy: "Copiar",
        copied: "Copiado"
      },

      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Você tem certeza que quer excluir esta Tag?",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
        table: {
          name: "Nome",
          color: "Cor",
          tickets: "Registros Tagdos",
          actions: "Ações",
        },
        buttons: {
          add: "Nova Tag",
        },
        toasts: {
          deleted: "Tag excluído com sucesso.",
        },
      },


      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },

          sendGreetingAccepted: {
            name: "Enviar saudação ao aceitar o ticket",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },

          userRating: {
            name: "Enviar pesquisa de satisfação",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },

          sendMsgTransfTicket: {
            name: "Enviar saudação ao transferir o ticket",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },

          // ADICAO DA TRADUCAO SELECT PARA HABILITAR MENSAGENS DE GRUPOS
          groupEnable: {
            name: "Habilitar Grupos",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          // FIM DO BLOCO DA TRADUCAO PRA HABILITAR MENSAGENS DE GRUPOS

          limitacoes:{
            quantidadeconexao: "Quantidade de Conexões",
            quantidadeusuario: "Quantidade de Usuários",
            tokenAssas: "Token Assas",
            ClienteID: "ID Cliente Assas",
          }

        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Deletar",
        transfer: "Transferir",
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message:
            "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
        ERR_USER_CREATION_COUNT: "Limite de usuários atingido, para alterar entre em contato com o suporte.",
        ERR_CONNECTION_CREATION_COUNT: "Limite de conexões atingido, para alterar entre em contato com o suporte.",
      },
    },
  },
};

export { messages };
