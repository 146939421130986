import { useState, useEffect } from "react";
import api from "../../services/api";

const useContacts = ({ searchParam }) => {
    const [loading, setLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setLoading(true);
        const fetchContacts = async () => {
            try {
                const { data } = await api.get("/contacts", {
                    params: {
                        searchParam,
                    },
                });
                setContacts(data.contacts);
                setCount(data.count);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        fetchContacts();
        return () => {};
    }, [searchParam]);

    return { contacts, loading, count };
};

export default useContacts;
